import React, { useEffect } from "react";
import Button from "./Button";
import { Link } from "react-router-dom";

const Footer: React.FC = ({ }) => {

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <footer className="bg-gradient-to-r from-slate-950 via-slate-800 to-slate-700 text-white" >
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        {/* Top Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8  py-20">
          {/* Logo and Contact */}
          <div>
            <div className="flex items-center">
              <img src="/FladdraLogo.png" className="h-10 mr-2" />
              <h2 className="text-lg font-bold">Fladdra®</h2>
            </div>
            <p className="mt-2">Transforming Ideas into Scalable Solutions</p>
            <p className="mt-1">info@fladdra.com</p>
            <p className="mt-1">+91 95611 09889</p>
          </div>

          {/* About Section */}
          <div>
            <h3 className="text-lg font-bold mb-2 ">Sitemap</h3>
            <ul >
              <li className="pb-1">
                <Link to="/about" onClick={scrollToTop}>About</Link>
              </li>
              <li className="pb-1">
                <Link to="/services" onClick={scrollToTop}>Services</Link>
              </li>
              <li className="pb-1">
                <Link to="/solutions" onClick={scrollToTop}>Solutions</Link>
              </li>
              <li className="pb-1">
                <Link to="/blog" onClick={scrollToTop}>Blogs</Link>
              </li>
              <li className="pb-1">
                <Link to="/careers" onClick={scrollToTop}>Careers</Link>
              </li>
              {/* Uncomment if needed */}
              {/* <li className="pb-1">
        <Link to="/contact">Contact</Link>
      </li> */}
              <li className="pb-1">
                <Link to="/contact" onClick={scrollToTop}>Contact Us</Link>
              </li>
            </ul>
          </div>

          {/* Services Section */}
          <div>
            <h3 className="text-lg font-bold mb-2">Services</h3>

            <ul>
              <li className="pb-1">
                <Link to="/services" onClick={scrollToTop}>Technology Consultancy</Link>
              </li>
              <li className="pb-1">
                <Link to="/services" onClick={scrollToTop}>UX/UI Design</Link>
              </li>
              <li className="pb-1">
                <Link to="/services" onClick={scrollToTop}>Web Development</Link>
              </li>
              <li className="pb-1">
                <Link to="/services" onClick={scrollToTop}>Mobile App Development</Link>
              </li>
              <li className="pb-1">
                <Link to="/services" onClick={scrollToTop}>Digital Marketing</Link>
              </li>
              <li className="pb-1">
                <Link to="/services" onClick={scrollToTop}>Quality Engineering</Link>
              </li>
              <li className="pb-1">
                <Link to="/services" onClick={scrollToTop}>DevOps Engineering</Link>
              </li>
              <li className="pb-1">
                <Link to="/services" onClick={scrollToTop}>Product Engineering</Link>
              </li>
              <li className="pb-1">
                <Link to="/services" onClick={scrollToTop}>AI and ML</Link>
              </li>
              <li className="pb-1">
                <Link to="/services" onClick={scrollToTop}>Support and Maintenance</Link>
              </li>
              <li className="pb-1">
                <Link to="/services" onClick={scrollToTop}>Staff Augmentation</Link>
              </li>
            </ul>


            {/* <p className="mt-4 text-lg font-semibold">Get In Touch</p> */}
          </div>


          {/* Industries Section */}
          <div>
            <h3 className="text-lg font-bold mb-2">Industries</h3>
            <ul>
              <li className="pb-1">Education</li>
              <li className="pb-1">Automobile</li>
              <li className="pb-1">E-commerce & Retail</li>
              <li className="pb-1">Banking & Fintech</li>
              <li className="pb-1">Healthcare & Medical</li>
            </ul>
          </div>
        </div>

        {/* Newsletter Section */}
        {/* <div className="mt-8 flex flex-col w-1/3 ml-auto">
          <h3 className="text-md font-semibold ">Subscribe to our newsletter</h3>
          <div>
            <input
              type="email"
              placeholder="Email"
              className="px-3 py-2 my-3 border border-gray-300 rounded-md w-full"
            />
            <Button
              onClick={() => null}
              label="Subscribe"
              circleHover={true}
              className="ml-auto"
            />
          </div>
        </div> */}
        {/* Bottom Section */}
        <div className="text-center border-t border-gray-600">
          <p className="py-4">© {new Date().getFullYear()} Fladdra. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;