import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import API from "../../../components/configs/API";
import toast from "react-hot-toast";

const FeedbackView = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const isNewFeedback = id === "new";

    const initialFeedbackData = {
        name: "",
        position: "",
        profile_of_user: "" as string | File,
        feedback_text: "",
        is_featured: false,
    };

    const [feedbackData, setFeedbackData] = useState(initialFeedbackData);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!isNewFeedback) {
            const fetchFeedbackData = async () => {
                setIsLoading(true);
                try {
                    const response = await API.get(
                        `${process.env.REACT_APP_API_URL}feedbacks/${id}`
                    );
                    setFeedbackData(response.data);
                } catch (error) {
                    console.error("Error fetching feedback data:", error);
                } finally {
                    setIsLoading(false);
                }
            };
            fetchFeedbackData();
        }
    }, [id, isNewFeedback]);

    const validationSchema = Yup.object({
        name: Yup.string().required("Name is required"),
        position: Yup.string().required("Position is required"),
        profile_of_user: Yup.mixed().test(
            "fileSize",
            "File size is too large (max 2MB)",
            (value) => !value || (value instanceof File && value.size <= 2 * 1024 * 1024)
        ),
        feedback_text: Yup.string().required("Feedback text is required"),
        is_featured: Yup.boolean().required("Featured status is required"),
    });

    const handleSubmit = async (values: typeof initialFeedbackData) => {
        try {
            const formData = new FormData();
            formData.append("name", values.name);
            formData.append("position", values.position);
            formData.append("feedback_text", values.feedback_text);
            formData.append("is_featured", values.is_featured.toString());

            if (values.profile_of_user instanceof File) {
                formData.append("profile_of_user", values.profile_of_user);
            }

            if (isNewFeedback) {
                const response = await API.post(
                    `${process.env.REACT_APP_API_URL}feedbacks`,
                    formData,
                    { headers: { "Content-Type": "multipart/form-data" } }
                );
                toast.success("Feedback Created Successfully");
                console.log("Created Feedback:", response.data);
            } else {
                const response = await API.put(
                    `${process.env.REACT_APP_API_URL}feedbacks/${id}`,
                    formData,
                    { headers: { "Content-Type": "multipart/form-data" } }
                );
                toast.success("Feedback Updated Successfully");
                console.log("Updated Feedback:", response.data);
            }

            navigate("/admin/feedback-admin");
        } catch (error: any) {
            console.error("Error submitting feedback data:", error);
            toast.error(error?.response?.data?.detail?.msg || "Something Went Wrong!");
        }
    };

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">
                {isNewFeedback ? "Create New Feedback" : "Edit Feedback"}
            </h1>
            {isLoading && !isNewFeedback ? (
                <p>Loading...</p>
            ) : (
                <Formik
                    initialValues={feedbackData}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={(values) => handleSubmit(values)}
                >
                    {({ setFieldValue }) => (
                        <Form className="space-y-4">
                            <div>
                                <label className="block mb-2 font-semibold">Name</label>
                                <Field
                                    type="text"
                                    name="name"
                                    className="border border-gray-300 rounded p-2 w-full"
                                />
                                <ErrorMessage
                                    name="name"
                                    component="div"
                                    className="text-red-500 text-sm"
                                />
                            </div>

                            <div>
                                <label className="block mb-2 font-semibold">Position</label>
                                <Field
                                    type="text"
                                    name="position"
                                    className="border border-gray-300 rounded p-2 w-full"
                                />
                                <ErrorMessage
                                    name="position"
                                    component="div"
                                    className="text-red-500 text-sm"
                                />
                            </div>

                            <div>
                                <label className="block mb-2 font-semibold">Profile</label>
                                <input
                                    type="file"
                                    onChange={(e) => {
                                        const file = e.target.files?.[0];
                                        if (file) {
                                            setFieldValue("profile_of_user", file);
                                        }
                                    }}
                                    className="border border-gray-300 rounded p-2 w-full"
                                />
                                <ErrorMessage
                                    name="profile_of_user"
                                    component="div"
                                    className="text-red-500 text-sm"
                                />
                            </div>

                            <div>
                                <label className="block mb-2 font-semibold">Feedback Text</label>
                                <Field
                                    as="textarea"
                                    name="feedback_text"
                                    rows={6}
                                    className="border border-gray-300 rounded p-2 w-full"
                                />
                                <ErrorMessage
                                    name="feedback_text"
                                    component="div"
                                    className="text-red-500 text-sm"
                                />
                            </div>

                            <div>
                                <label className="block mb-2 font-semibold">Featured</label>
                                <Field
                                    as="select"
                                    name="is_featured"
                                    className="border border-gray-300 rounded p-2 w-full"
                                >
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </Field>
                                <ErrorMessage
                                    name="is_featured"
                                    component="div"
                                    className="text-red-500 text-sm"
                                />
                            </div>

                            <button
                                type="submit"
                                className="bg-blue-500 text-white rounded py-2 px-4 hover:bg-blue-600"
                            >
                                {isNewFeedback ? "Create Feedback" : "Update Feedback"}
                            </button>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};

export default FeedbackView;
