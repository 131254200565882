import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import API from '../../components/configs/API';
import BlogHeader from './BlogHeader';
import Button from '../../components/Button';
import { ArrowLeft, Checkmark, Copy, LogoLinkedin } from '@carbon/icons-react';
import "react-quill/dist/quill.snow.css";
import { CircularProgress } from '@mui/material';


interface BlogPost {
    id: string;
    title: string;
    content: string;
    link: string;
    image: string;
    created_on: string;
    updated_on: string;
    is_published: boolean;
    is_deleted: boolean;
    description: string
}

const BlogOverview = () => {
    const { id } = useParams<{ id: string }>();
    const [blogDetail, setBlogDetails] = React.useState<BlogPost | null>(null);
    const [loading, setLoading] = React.useState(true);
    const [copied, setCopied] = React.useState(false);

    const navigate = useNavigate();


    const handleCopyClick = () => {
        navigator.clipboard.writeText(window.location.href)
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
            })
            .catch((error) => {
                console.error("Failed to copy: ", error);
            });
    };

    const handleBackClick = () => {
        navigate(-1);
    };
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    React.useEffect(() => {
        const fetchBlogDetails = async () => {
            try {
                const response = await API.get(`${process.env.REACT_APP_BACK_HOST}api/blog/${id}`);
                setBlogDetails(response?.data);
            } catch (error) {
                console.error('Error fetching blog details:', error);
            } finally {
                setLoading(false);
            }
        };
        scrollToTop()
        fetchBlogDetails();
    }, [id]);

    
    
    if (loading) {
        return (
            <div className='mt-32 flex flex-1 justify-center items-center'>
                <CircularProgress size={40}/>
            </div>
        );
    }

    if (!blogDetail) {
        return (
            <div>
                <BlogHeader />
                Blog not found
            </div>
        );
    }

    return (
        <>
            <div className=" lg:w-[94%] lg:p-10 p-5 mx-auto mt-20 lg:shadow-lg mb-20">
                <div className='flex justify-between mb-5'>

                    <button
                        onClick={handleBackClick}
                        className="text-accent hover:text-gray-900 font-semibold lg:text-lg text-sm  flex items-center space-x-2">
                        <ArrowLeft size={20} /> <p>Back</p>
                    </button>
                    <button
                        onClick={handleCopyClick}
                        className="ml-4 text-accent hover:text-accent focus:outline-none lg:text-lg text-sm "
                        aria-label="Copy Page URL"
                    >
                        {copied ? (
                            <span className="text-green-500 flex "><Checkmark size={20} className='flex mr-2 ' /> Copied!</span> 
                        ) : (
                            <span className='flex '><Copy size={20} className='flex mr-2' />Copy Blog Link</span>
                        )}
                    </button>

                </div>
                <div className="mb-6">
                    <h1 className="lg:text-3xl text-lg font-bold text-gray-900">{blogDetail?.title}</h1>
                    <div className="border-t-2 border-gray-300 mt-2"></div>
                </div>

                <div className="text-sm text-gray-500 mb-4 flex justify-between ">
                    <span className='mt-1'>Published on: {new Date(blogDetail?.created_on).toLocaleDateString()}</span>
                    <a
                        href="https://www.linkedin.com/company/fladdra"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ml-4 text-blue-500 hover:text-blue-700 focus:outline-none flex items-center "
                        aria-label="Visit LinkedIn Page"
                    >
                        <span className='flex items-center text-gray-500 '>Follow us on linkedin:</span>
                        <LogoLinkedin size={30} />
                    </a>

                </div>

                <div className="">
                    <img
                        src={`${process.env.REACT_APP_BACK_HOST}${blogDetail?.image.replace(/\\/g, '/')}`}
                        alt={blogDetail?.title}
                        className="w-full lg:h-96 object-contain object-center rounded-lg"
                    />
                </div>

                <div className="mt-10  text-justify">
                    <div className="mb-6 lg:text-lg text-gray-600 mt-2 content-area">
                        <div dangerouslySetInnerHTML={{ __html: blogDetail?.description }} />
                    </div>

                    <div className="mb-6 lg:text-lg text-gray-600 mt-2 content-area">
                        <div className="content-area" dangerouslySetInnerHTML={{ __html: blogDetail?.content }} />
                    </div>


                    {/* <div className="mt-6">
                        <h2 className="lg:text-xl font-semibold text-gray-700">Link</h2>
                        <a
                            href={`${blogDetail?.link}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-indigo-600 hover:text-indigo-800 mt-2 inline-block  font-medium"
                        >
                            {blogDetail?.link}
                        </a>
                    </div> */}
                </div>
            </div>

        </>

    );
};

export default BlogOverview;
