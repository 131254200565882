// components/Button.tsx
import React, { ReactNode } from 'react';

interface ButtonProps {
  label: string | ReactNode;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  circleHover?: boolean; // Optional prop for conditional class
  children?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({ label, onClick, type = 'button', className, circleHover }) => {

  const baseStyles = 'px-4 py-2 bg-blue-500 text-white rounded ';
  const circleHoverStyles = 'rounded relative flex h-[50px] px-6 hover:before:text-transparent hover:text-white items-center justify-center overflow-hidden bg-gray-800 text-white shadow-2xl transition-all before:absolute before:h-0 before:w-0 before:rounded-full before:bg-accent before:duration-500  hover:before:h-56 hover:before:w-full hover:before:rounded-lg hover:before:text-white';
  const combinedClassName = `${circleHover ? circleHoverStyles : baseStyles} ${className || ''}`;

  return (
    <button
      type={type}
      onClick={onClick}
      className={combinedClassName}
    >
      <span className={'z-10 hover:text-white  hover:font-bold  text-transparent hover:before:text-transparent text-white '}>{label}</span>
    </button>
  );
};

export default Button;
