import React from 'react';
import { Box, Paper } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

interface CustomDataGridProps {
  rows: any[];
  columns: GridColDef[];
}

// const MIN_ROWS = 1; // Minimum number of rows to show

const CustomDataGrid: React.FC<CustomDataGridProps> = ({ rows, columns }) => {
  // Ensure at least 10 rows are displayed
  const paddedRows = [...rows];

  // while (paddedRows.length < MIN_ROWS) {
  //   paddedRows.push({
  //     id: `empty-${paddedRows.length}`, // Unique ID for placeholder rows
  //     ...columns.reduce((acc, col) => ({ ...acc, [col.field]: '' }), {}), // Empty values for all columns
  //   });
  // }

  return (
    <Box sx={{ width: '100%', padding: '20px' }} className="min-h-screen">
      <Paper sx={{ width: '100%', mb: 2, boxShadow: 3, borderRadius: 2 }}>
        <DataGrid
          rows={paddedRows}
          columns={columns}
          // initialState={{
          //   pagination: {
          //     paginationModel: { pageSize: MIN_ROWS, page: 0 }, // Set default page size
          //   },
          // }}
          pageSizeOptions={[100,50,10]} // Optional: Restrict page size to 10
          disableColumnMenu // Optional: Disable column menu for cleaner UI
          sx={{
            boxShadow: 3,
            border: 1,
            borderColor: 'grey.300',
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: '#edf5ff',
              color: 'black',
              borderRight: '1px solid #ddd',
            },
            '& .MuiDataGrid-cell': {
              borderRight: '1px solid #ddd',
              borderBottom: '1px solid #ddd',
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
              padding: '10px',
            },
            '& .MuiDataGrid-row': {
              borderBottom: '1px solid #ddd',
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: '2px solid #ccc',
            },
          }}
        />
      </Paper>
    </Box>
  );
};

export default CustomDataGrid;
