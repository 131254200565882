import { GridColDef } from "@mui/x-data-grid";
import { Link } from "react-router-dom";


export const columns: GridColDef[] = [
  // {
  //   headerName: "ID",
  //   field: "id", 
  //   headerAlign: "center",
  //   align: "center",
  //   flex: 1,
  // },
  {
    headerName: "Name",
    field: "name",
    headerAlign: "left",
    align: "left",
    flex: 0.5,
  },


 
  
  {
    headerName: "Image",
    field: "image",
    headerAlign: "center",
    align: "center",
    width: 220,
    // flex:1,
    renderCell: (params) => {
      const imageUrl = params.value ? process.env.REACT_APP_BACK_HOST + params.value : null;
    //   console.log( process.env.REACT_APP_BACK_HOST,'----------------',imageUrl)
      return imageUrl ? (
        <img
          src={imageUrl}
          alt="thumbnail"
          className="w-12 h-12 object-cover rounded-md"
        />
      ) : (
        "N/A"
      );  
    },
  },
  {
    headerName: "Actions",
    field: "actions", 
    headerAlign: "center",
    align: "center",
    flex: 0.6,
    renderCell: (params) => (
      <Link
        to={`/admin/brands/${params.row.id}`} 
        className="text-blue-500 underline hover:text-blue-700"
      >
        View
      </Link>
    ),
  },
 
];
