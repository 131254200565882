import React, { useState, useEffect } from 'react';
import Button from '../../components/Button';
import { Fade, Slide } from "react-awesome-reveal";
import { useNavigate } from 'react-router-dom';

function Header() {
    const navigate = useNavigate();
    
    const services = [
        'web applications',
        'AI-driven solutions',
        'ITSM systems',
        'CRM platforms',
        'mobile experiences',
        'end-to-end technology stacks'
    ];
    
    const header_images = [
        { image: '/headerImages/header1.png' },
        { image: '/headerImages/header2.png' },
        { image: '/headerImages/header3.png' },
        { image: '/headerImages/header4.png' },
        { image: '/headerImages/header5.png' },
        { image: '/headerImages/header6.png' },
    ];

    const [currentService, setCurrentService] = useState(services[0]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const serviceInterval = setInterval(() => {
            setCurrentService(prev => {
                const currentIndex = services.indexOf(prev);
                const nextIndex = (currentIndex + 1) % services.length;
                return services[nextIndex];
            });
        }, 4000);

        const imageInterval = setInterval(() => {
            setCurrentImageIndex(prev => {
                const nextIndex = (prev + 1) % header_images.length;
                return nextIndex;
            });
        }, 4000); 

        return () => {
            clearInterval(serviceInterval);
            clearInterval(imageInterval);
        };
    }, [services, header_images]);

    return (
        // bg-gradient-to-bl from-[#feb796] via-[#fae1d5] to-white
        <div className='w-full flex mt-16 flex-col-reverse md:flex md:flex-row md:pb-28 md:mt-16 py-12 md:px-20 '>
            <div className='px-4  md:w-3/5'>
                <div className=''>
                    <h2 className='md:text-5xl text-2xl font-semibold pt-20 md:pt-24 text-gray-800'>
                        Transforming your business with
                        <Fade key={currentService} duration={1000} direction='down'>
                            <span className="bg-gradient-to-r from-[#b7291c] to-[#671414] bg-clip-text text-transparent">
                                {currentService}
                            </span>
                        </Fade>
                        and tailored digital solutions
                    </h2>
                    <p className='md:text-lg font-semibold mt-6 md:mt-8 w-[90%] md:w-[80%] text-gray-700'>
                        Our expertise spans across high-performance web and mobile applications,<br /> AI-driven innovations, ITSM systems, and more—empowering your business for the future.
                    </p>
                    <Button onClick={() => navigate('/contact')} label='Connect with us' circleHover={true} className='mt-10 lg:text-xl text-sm bg-accent before:bg-[#e63434] hover:before:bg-[#e63434]' />
                </div>
            </div>
            
            <div className='md:w-2/5 h-[300px] md:h-auto overflow-hidden flex items-center'>
                <Fade key={currentImageIndex} direction="right" duration={1000}>
                    <img
                        src={header_images[currentImageIndex].image}
                        alt="Header"
                        className="md:max-h-full md:max-w-full object-contain "
                    />
                </Fade>
            </div>
        </div>
    );
}

export default Header;
