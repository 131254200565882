import React, { useEffect, useState } from "react";
import axios from "axios";
interface CardData {
  id: string;
  image: string;
  title: string;
  summary: string;
  linkText: string;
  link: string;
}



const CardsComponent = () => {
  const [cardData, setCardData] = useState<CardData[]>([]);

  const fetchCardData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}ourclient`);
      const data = response.data;

      const transformedData = data.map((item: any) => ({
        id: item.id,
        image: item.image,
        title: item.name,
        summary: item.description,
        linkText: "Read case study",
        link: item.link
      }));

      setCardData(transformedData);
    } catch (error) {
      console.error("Error fetching card data:", error);
    }
  };

  useEffect(() => {
    fetchCardData();
  }, []);

  return (
    <section className="md:py-28 py-8 bg-gray-50">
  <div className="px-4 lg:px-20">
      <h2 className="flex justify-between items-center mb-5 text-3xl md:font-bold font-semibold text-transparent bg-gradient-to-r from-accent to-[#3f0d09] bg-clip-text">
        Our Work
      </h2>

    {/* Cards Section */}
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
      {cardData.map((card) => (
       <a
       key={card.id}
       href={card.link.replace(/^"|"$/g, '')}
       target="_blank"
       rel="noopener noreferrer"
       className="bg-white shadow-lg rounded-xl  hover:scale-105 hover:shadow-2xl transition-transform duration-300 border border-gray-200"
     >
       <div className="flex flex-col justify-between h-full ">
         {/* Card Image */}
         <div className="flex justify-center items-center w-full h-48 rounded-t-lg">
           <img
             src={`${process.env.REACT_APP_BACK_HOST}${card.image}`}
             alt={card.title}
             className="max-h-full max-w-full object-contain p-2"
           />
         </div>
         {/* Card Content */}
         <div className="p-6 flex flex-col flex-grow h-48 rounded-b-xl bg-gray-100 ">
           <h3 className="text-lg font-semibold text-gray-800 mb-2 truncate">
             {card.title}
           </h3>
           <div className="text-gray-600 text-sm  overflow-hidden text-ellipsis ">
             {card.summary.slice(0,200)} ...
           </div>
           <div className="mt-auto">
             <button className="text-blue-600 hover:text-blue-800 font-medium text-sm">
               {card.linkText}
             </button>
           </div>
         </div>
       </div>
     </a>
     
      ))}
    </div>
  </div>
</section>


  );
};

export default CardsComponent;
