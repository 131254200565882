import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; 
import API from "../../../components/configs/API";
import toast from "react-hot-toast";

const BlogView = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const isNewBlog = id === "new";

    const initialBlogData = {
        title: "",
        content: "",
        is_published: false,
        link: "",
        description: "",
        image: "" as string | File,
    };

    const [blogData, setBlogData] = useState(initialBlogData);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!isNewBlog) {
            const fetchBlogData = async () => {
                setIsLoading(true);
                try {
                    const response = await API.get(
                        `${process.env.REACT_APP_API_URL}blog/${id}`
                    );
                    setBlogData(response.data);
                } catch (error) {
                    console.error("Error fetching blog data:", error);
                } finally {
                    setIsLoading(false);
                }
            };
            fetchBlogData();
        }
    }, [id, isNewBlog]);

    const validationSchema = Yup.object({
        title: Yup.string()
            .required("Title is required")
            .min(5, "Title must be at least 5 characters"),
        content: Yup.string()
            .required("Content is required")
            .min(20, "Content must be at least 20 characters"),
        is_published: Yup.boolean().required("Published status is required"),
        link: Yup.string()
            .url("Must be a valid URL")
            .required("Link is required"),
        description: Yup.string().required("Description is required"),
    });

    const handleSubmit = async (values: typeof initialBlogData) => {
        try {
            const formData = new FormData();
            formData.append("title", values.title);
            formData.append("content", values.content);
            formData.append("is_published", values.is_published.toString());
            formData.append("link", values.link);
            formData.append("description", values.description);

            if (values.image instanceof File) {
                formData.append("image", values.image);
            }

            const storedUserData = localStorage.getItem("token");
            if (isNewBlog) {
                await API.post(`${process.env.REACT_APP_API_URL}blog_post`, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                });
                toast.success("Blog Created Successfully");
            } else {
                await API.put(`${process.env.REACT_APP_API_URL}blog_put/${id}`, formData, {
                    headers: { "Content-Type": "multipart/form-data", Authorization: storedUserData },
                });
                toast.success("Blog Updated Successfully");
            }

            navigate("/admin/blog");
        } catch (error: any) {
            console.error("Error submitting blog data:", error);
            toast.error(error?.response?.data?.detail?.msg || "Something Went Wrong!");
        }
    };

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">
                {isNewBlog ? "Create New Blog" : "Edit Blog"}
            </h1>
            {isLoading && !isNewBlog ? (
                <p>Loading...</p>
            ) : (
                <Formik
                    initialValues={blogData}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={(values) => handleSubmit(values)}
                >
                    {({ setFieldValue, values }) => (
                        <Form className="space-y-4">
                            <div>
                                <label className="block mb-2 font-semibold">Title</label>
                                <Field
                                    type="text"
                                    name="title"
                                    className="border border-gray-300 rounded p-2 w-full"
                                />
                                <ErrorMessage
                                    name="title"
                                    component="div"
                                    className="text-red-500 text-sm"
                                />
                            </div>


                            <div>
                                <label className="block mb-2 font-semibold">Description</label>
                                <ReactQuill
                                    value={values.description}
                                    onChange={(value) => setFieldValue("description", value)}
                                    
                                />
                                <ErrorMessage
                                    name="description"
                                    component="div"
                                    className="text-red-500 text-sm"
                                />
                            </div>
                            <div>
                                <label className="block mb-2 font-semibold">Content</label>
                                <ReactQuill
                                    value={values.content}
                                    onChange={(value) => setFieldValue("content", value)}
                                    className="w-full "
                                />
                                <ErrorMessage
                                    name="content"
                                    component="div"
                                    className="text-red-500 text-sm"
                                />
                            </div>


                            <div>
                                <label className="block mb-2 font-semibold">Published</label>
                                <Field
                                    as="select"
                                    name="is_published"
                                    className="border border-gray-300 rounded p-2 w-full"
                                >
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </Field>
                                <ErrorMessage
                                    name="is_published"
                                    component="div"
                                    className="text-red-500 text-sm"
                                />
                            </div>

                            <div>
                                <label className="block mb-2 font-semibold">Link</label>
                                <Field
                                    type="text"
                                    name="link"
                                    className="border border-gray-300 rounded p-2 w-full"
                                />
                                <ErrorMessage
                                    name="link"
                                    component="div"
                                    className="text-red-500 text-sm"
                                />
                            </div>

                            <div>
                                <label className="block mb-2 font-semibold">Image</label>
                                <input
                                    type="file"
                                    onChange={(e) => {
                                        const file = e.target.files?.[0];
                                        if (file) setFieldValue("image", file);
                                    }}
                                    className="border border-gray-300 rounded p-2 w-full"
                                />
                                <ErrorMessage
                                    name="image"
                                    component="div"
                                    className="text-red-500 text-sm"
                                />
                            </div>

                            <button
                                type="submit"
                                className="bg-blue-500 text-white rounded py-2 px-4 hover:bg-blue-600"
                            >
                                {isNewBlog ? "Create Blog" : "Update Blog"}
                            </button>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};

export default BlogView;
