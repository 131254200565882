// src/pages/LandingPage.tsx
import React from 'react';
import GetInTouch from '../landingpage/GetInTouch';
import AboutHeader from './AboutHeader';
import DigitalSolutions from './DigitalSolutions';
import CoreValues from './CoreValues';
import { OurTeam } from './OurTeam';
// import OurTeam from './OurTeam';


const About: React.FC = () => {
    return (
        <div className=''>
           <AboutHeader/>
           <DigitalSolutions/>
           <GetInTouch/>
           <CoreValues/>
            <OurTeam/>
        </div>
    );
};

export default About;
