
import axios from "axios";
const storedUserData = JSON.parse(localStorage.getItem('token') as any);
// const token = JSON.parse(storedUserData);

const API = axios.create({timeout: 300000,
  baseURL: process.env.REACT_APP_API_URL, 
  headers: {
    'Content-Type': 'application/json',
    Authorization: `${storedUserData}`
  }
});

API.interceptors.request.use(
  function (config) {
    const storedUserData = localStorage.getItem('token');
    if (storedUserData){
      console.log('insideeeeeeeeee')
      console.log('storedUserDatastoredUserData', storedUserData)
      const token = JSON.parse(storedUserData);
      console.log('inside api',token);
      if (token) {
        if (config.headers) {
          config.headers["Authorization"] = token;
        }
      }
    }
    return config;
  },
  function (error) {
      return Promise.reject(error);
  }
);

export default API;

// export const serverAPI = axios.create({
//   baseURL: process.env.NEXT_PUBLIC_SERVER_API_URL,
// });
