// import { rows } from './data';
import CustomDataGrid from '../../../components/CustomDataGrid';
import { useEffect, useState } from 'react';
import API from '../../../components/configs/API';
import { useNavigate } from 'react-router-dom';
import { columns } from './coumns';

export default function AdminOurWorkPage() {
  const navigate = useNavigate();


  const [rows, setRows] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    console.log('Fetching data...');
    const fetchData = async () => {
      try {
        const response = await API.get(`${process.env.REACT_APP_API_URL}ourclient`);
        console.log('Data fetched:', response.data);
        setRows(response.data);
      } catch (error) {
        setError('Failed to fetch data');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  if (loading) {
    return <div>Loading...</div>;
  }

  if (rows.length == 0) {
    return (
      <>
        <div className=' flex justify-end'>

          <button
            onClick={() => navigate("/admin/work/new")}
            className=" text-blue-400 border  rounded  py-2 px-4 "
          >
            Add New Work
          </button>
        </div>
        <div>No Data To show...</div>
      </>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div >
      <div className=' flex justify-end'>

        <button
          onClick={() => navigate("/admin/work/new")}
          className=" text-blue-400 border  rounded  py-2 px-4 "
        >
          Add New Work
        </button>
      </div>
      <CustomDataGrid rows={rows} columns={columns} />
    </div>
  );
}
