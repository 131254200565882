import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { BiLogoLinkedinSquare, BiLogoDribbble } from "react-icons/bi";
import { FaTwitter } from "react-icons/fa6";
import toast from "react-hot-toast";

const WorkUpdate = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const isNewWork = id === "new";

    const initialClientData = {
        image: {
            src: "",
            alt: "",
        },
        name: "",
        project: "",
        description: "",
        link: "",
    };

    const [teamData, setTeamData] = useState(initialClientData);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!isNewWork) {
            const fetchTeamData = async () => {
                setIsLoading(true);
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_URL}ourclient/${id}`
                    );
                    setTeamData(response.data);
                } catch (error) {
                    console.error("Error fetching team data:", error);
                } finally {
                    setIsLoading(false);
                }
            };
            fetchTeamData();
        }
    }, [id, isNewWork]);

    const validationSchema = Yup.object({
        name: Yup.string()
            .required("Name is required")
            .min(3, "Name must be at least 3 characters"),
        project: Yup.string().required("Job Title is required"),
        description: Yup.string().required("Description is required"),
        image: Yup.mixed().required("Image is required"),
        link:Yup.string().required("Link is required"),
    });

    const handleSubmit = async (values: typeof initialClientData) => {
        try {
            const formData = new FormData();
            formData.append("name", values.name);
            formData.append("project", values.project);
            formData.append("description", values.description);

            if (values.image instanceof File) {
                formData.append("image", values.image);
            }

            formData.append("link", values.link);
            const storedUserData = localStorage.getItem ('token');

            const parsedToken = storedUserData ? JSON.parse(storedUserData) : null;
            if (isNewWork) {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}ourclient_post`,
                    formData,{
                        headers:{Authorization: parsedToken}
                    }
                );
                console.log("Created Team:", response.data);
            } else {
                const response = await axios.put(
                    `${process.env.REACT_APP_API_URL}ourclient/${id}`,
                    formData,{
                        headers:{Authorization: storedUserData}

                    }
                );
                console.log("Updated Team:", response.data);
            }

            navigate("/admin/work");
        } catch (error: any) {
    console.error("Error submitting team data:", error);

    const errorDetail = error?.response?.data?.detail;

    if (Array.isArray(errorDetail)) {
        // If the detail is an array, join the messages
        toast.error(errorDetail.map((err: any) => err.msg || err).join(", "));
    } else if (typeof errorDetail === "object") {
        // If the detail is an object, stringify it or show a specific key
        toast.error(JSON.stringify(errorDetail));
    } else {
        // Fallback to a plain message
        toast.error(errorDetail || "An error occurred");
    }
}
    };

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">
                {isNewWork ? "Create New OurWork" : "Edit OurWork"}
            </h1>
            {isLoading && !isNewWork ? (
                <p>Loading...</p>
            ) : (
                <Formik
                    initialValues={teamData}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={(values) => handleSubmit(values)}
                >
                    {({ setFieldValue }) => (
                        <Form className="space-y-4">
                            <div>
                                <label className="block mb-2 font-semibold">Name</label>
                                <Field
                                    type="text"
                                    name="name"
                                    className="border border-gray-300 rounded p-2 w-full"
                                />
                                <ErrorMessage
                                    name="name"
                                    component="div"
                                    className="text-red-500 text-sm"
                                />
                            </div>

                            <div>
                                <label className="block mb-2 font-semibold">Project Title</label>
                                <Field
                                    type="text"
                                    name="project"
                                    className="border border-gray-300 rounded p-2 w-full"
                                />
                                <ErrorMessage
                                    name="project"
                                    component="div"
                                    className="text-red-500 text-sm"
                                />
                            </div>

                            <div>
                                <label className="block mb-2 font-semibold">Description</label>
                                <Field
                                    as="textarea"
                                    name="description"
                                    rows={4}
                                    className="border border-gray-300 rounded p-2 w-full"
                                />
                                <ErrorMessage
                                    name="description"
                                    component="div"
                                    className="text-red-500 text-sm"
                                />
                            </div>

                            <div>
                                <label className="block mb-2 font-semibold">Image</label>
                                <input
                                    type="file"
                                    onChange={(e) => {
                                        const file = e.target.files?.[0];
                                        if (file) {
                                            setFieldValue("image", file);
                                        }
                                    }}
                                    className="border border-gray-300 rounded p-2 w-full"
                                />
                                <ErrorMessage
                                    name="image"
                                    component="div"
                                    className="text-red-500 text-sm"
                                />
                            </div>

                            <div>
                                <label className="block mb-2 font-semibold"> Link</label>
                                <Field
                                    as="textarea"
                                    name="link"
                                    rows={4}
                                    // placeholder='[{ "href": "https://...",  }]'
                                    className="border border-gray-300 rounded p-2 w-full"
                                />
                                <ErrorMessage
                                    name="link"
                                    component="div"
                                    className="text-red-500 text-sm"
                                />
                            </div>

                            <button
                                type="submit"
                                className="bg-blue-500 text-white rounded py-2 px-4 hover:bg-blue-600"
                            >
                                {isNewWork ? "Create Our Work" : "Update OurWork"}
                            </button>
                        </Form> 
                    )}
                </Formik>
            )}
        </div>
    );
};

export default WorkUpdate;
