// import { rows } from './data';
import CustomDataGrid from '../../../components/CustomDataGrid';
// import { columns } from './columns';
import { useEffect, useState } from 'react';
import API from '../../../components/configs/API';
import { useNavigate } from 'react-router-dom';
import { columns } from './columns';

export default function AdminContactPage() {
    const navigate = useNavigate();


    const [rows, setRows] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await API.get(`${process.env.REACT_APP_API_URL}contact`);

                setRows(response.data);
            } catch (error) {
                setError('Failed to fetch data');
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div >
            <div className=' flex justify-end'>
            </div>
            <CustomDataGrid rows={rows} columns={columns} />
        </div>
    );
}
