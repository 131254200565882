import React, { useEffect } from 'react';

const TopmateEmbed = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://topmate-embed.s3.ap-south-1.amazonaws.com/v1/topmate-embed.js';
    script.async = true;
    script.defer = true;
    script.setAttribute('user-profile', 'https://topmate.io/embed/profile/khizer_shaikh?theme=D5534D');
    script.setAttribute('btn-style', '{"backgroundColor":"#000","color":"#fff","border":"1px solid #000"}');
    script.setAttribute('embed-version', 'v1');
    script.setAttribute('button-text', "Let's Connect");
    script.setAttribute('position-right', '30px');
    script.setAttribute('position-bottom', '30px');
    script.setAttribute('custom-padding', '0px');
    script.setAttribute('custom-font-size', '16px');
    script.setAttribute('custom-font-weight', '500');
    script.setAttribute('custom-width', '200px');

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null; // or return a wrapper if you want to display something
};

export default TopmateEmbed;
