import React from "react";
import Button from "../../components/Button";
import { Link } from "react-router-dom";

const GetInTouch: React.FC = () => {
    return (
        <section className="flex flex-col-reverse md:flex-row items-center justify-between md:h-[50vh] bg-gradient-to-r from-slate-950 via-slate-800 to-slate-700 md:px-20 px-10 py-10 gap-x-8">
            {/* Text Section */}
            <div className="text-left md:w-2/5 mt-10 md:mt-0">
                <h2 className="text-5xl text-white font-bold mb-4">Get In Touch</h2>
                <p className="md:text-base text-justify text-neutral-200">
                    Let's build and scale your digital products. We are always open to
                    discuss new projects, creative ideas, or opportunities to be part of
                    your vision.
                </p>
                <Link to="/contact">
                    <Button
                        label="Let's Talk"
                        circleHover={true}
                        className="mt-8 w-full md:w-1/2  before:bg-gray-800 bg-red-800"
                    />
                </Link>
            </div>

            {/* Image Section */}
            <div className="md:w-3/5 flex justify-end items-center md:h-[40vh]">
                <img
                    src="/connect.png"
                    alt="Header"
                    className="h-full  object-contain md:ml-auto"
                />
            </div>
        </section>
    );
};

export default GetInTouch;
