import React, { useEffect, useState } from "react";
import { BiLogoDribbble, BiLogoLinkedinSquare } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import axios from "axios";

// Types
type ImageProps = {
  src: string;
  alt?: string;
};

type SocialLink = {
  href: string;
  icon: React.ReactNode;
};

type TeamMember = {
  image: ImageProps;
  name: string;
  jobTitle: string;
  description: string;
  socialLinks: SocialLink[];
};

type Footer = {
  heading: string;
  description: string;
  button: {
    title: string;
    onClick?: () => void;
  };
};

type Props = {
  tagline: string;
  heading: string;
  description: string;
  footer: Footer;
};

export const OurTeam: React.FC<Partial<Props>> = (props) => {
  const { tagline, heading, description, footer } = {
    tagline: "Our Amazing Team", // Default tagline
    heading: "Meet the Team", // Default heading
    description:
      "We are a group of talented individuals passionate about creating amazing experiences for our users.", // Default description
    footer: {
      heading: "Join Our Team", // Default footer heading
      description: "We are always looking for talented individuals to join our growing team.", // Default footer description
    },
    ...props,
  };

  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Fetch team members from API
  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACK_HOST}api/developer_profiles`
        );
        
        const transformedData = response.data.profiles
          .filter((profile: any) => profile.is_active && !profile.is_deleted) // Filter active members
          .map((profile: any): TeamMember => ({
            image: {
              src: profile.image, // Assuming image is a URL from the API
              alt: profile.name,
            },
            name: profile.name,
            jobTitle: profile.job_title,
            description: profile.description,
            socialLinks: profile.linkedin
              ? [{ href: profile.linkedin, icon: <BiLogoLinkedinSquare className="size-6" /> }]
              : [],
          }));

        setTeamMembers(transformedData);
      } catch (err) {
        // setError("Failed to load team members");
        console.log(err, 'in the team')
      } finally {
        setIsLoading(false);
      }
    };

    fetchTeamMembers();
  }, []);

  // Split CEO and other members
  const ceo = teamMembers.find((member) => member.jobTitle === "CEO");
  const otherMembers = teamMembers.filter((member) => member.jobTitle !== "CEO");

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <section id="our-team" className="px-6 md:py-24 bg-slate-100">
      <div className="container mx-auto w-[80%]">
        {/* Header Section */}
        <div className="mb-12 md:mb-18 lg:mb-20 text-center">
          <p className="mb-3 text-lg sm:text-xl md:text-2xl text-gray-600 md:mb-4">{tagline}</p>
          <h2 className="mb-5 bg-gradient-to-r from-accent to-[#3f0d09] bg-clip-text text-transparent text-3xl font-bold md:mb-6 md:text-5xl">{heading}</h2>
          <p className="text-lg sm:text-xl md:text-2xl text-gray-600 ">{description}</p>
        </div>

        {/* CEO Section */}
        {ceo && (
          <div className="mb-12 flex flex-col items-center text-center">
            <div className="mb-5 h-20 w-20 overflow-hidden rounded-full md:mb-6">
              <img
                src={`${process.env.REACT_APP_BACK_HOST}${ceo.image.src}`}
                alt={ceo.image.alt || "Team Member"}
                className="h-full w-full object-cover"
              />
            </div>
            <div className="mb-3 md:mb-4">
              <h5 className="text-md font-semibold text-gray-800 md:text-lg">{ceo.name}</h5>
              <h6 className="text-sm text-gray-500 md:text-md">{ceo.jobTitle}</h6>
            </div>
            <p className="text-sm text-gray-600">{ceo.description}</p>
            <div className="mt-4 flex gap-3.5">
              {ceo.socialLinks.map((link, index) => (
                <a
                  key={index}
                  href={link.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-500 hover:text-blue-600"
                >
                  {link.icon}

                </a>
              ))}
            </div>
          </div>
        )}

        {/* Other Team Members */}
        <div className="grid grid-cols-1 gap-x-8 gap-y-12 md:grid-cols-2 lg:grid-cols-3 md:gap-y-16">
          {otherMembers.map((member, index) => (
            <TeamMemberCard key={index} member={member} />
          ))}
        </div>

        {/* Footer Section */}
        <div className="mt-14 md:mt-20 lg:mt-24 text-center">
          <h4 className="mb-3 text-xl font-bold text-gray-800 md:mb-4 md:text-2xl">{footer.heading}</h4>
          <p className="text-gray-600 md:text-lg">{footer.description}</p>
          <div className="flex justify-center">
            <Link to="/careers">
              <Button
                label="View Open Positions"
                circleHover={true}
                className="mt-6 rounded-lg px-6 py-2 text-white md:mt-8"
              />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

// TeamMemberCard Subcomponent
const TeamMemberCard: React.FC<{ member: TeamMember }> = ({ member }) => {
  return (
    <div className="flex flex-col items-center text-center">
      <div className="mb-5 h-20 w-20 overflow-hidden rounded-full md:mb-6">
        <img
          src={`${process.env.REACT_APP_BACK_HOST}${member.image.src}`}
          alt={member.image.alt || "Team Member"}
          className="h-full w-full object-cover"
        />
      </div>
      <div className="mb-3 md:mb-4">
        <h5 className="text-md font-semibold text-gray-800 md:text-lg">{member.name}</h5>
        <h6 className="text-sm text-gray-500 md:text-md">{member.jobTitle}</h6>
      </div>
      <p className="text-sm text-gray-600">{member.description}</p>
      <div className="mt-4 flex gap-3.5">
        {member.socialLinks.map((link, index) => (
          <a
            key={index}
            href={link.href}
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-500 hover:text-blue-600"
          >
            {link.icon}
          </a>
        ))}
      </div>
    </div>
  );
};
