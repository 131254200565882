import { GridColDef } from "@mui/x-data-grid";
import { CheckmarkOutline, CheckmarkOutlineError } from "@carbon/icons-react";
import { Link } from "react-router-dom";

export const columns: GridColDef[] = [
  {
    headerName: "Name",
    field: "name",
    headerAlign: "left",
    align: "left",
    flex: 1.2,
  },
  {
    headerName: "Position",
    field: "position",
    headerAlign: "left",
    align: "left",
    flex: 1,
  },
  {
    headerName: "Profile Image",
    field: "profile_of_user",
    headerAlign: "center",
    align: "center",
    width: 120,
    renderCell: (params) => {
      const imageUrl = params.value ? process.env.REACT_APP_BACK_HOST + params.value : null;
      return imageUrl ? (
        <img
          src={imageUrl}
          alt="thumbnail"
          className="w-12 h-12 object-cover rounded-md"
        />
      ) : (
        "N/A"
      );
    },
  },

  {
    headerName: "Feedback",
    field: "feedback_text",
    headerAlign: "left",
    align: "left",
    flex: 2,
    renderCell: (params) => (
      <div>
        {params.value.length > 100
          ? `${params.value.substring(0, 100)}...`
          : params.value}
      </div>
    ),
  },
  {
    headerName: "Featured",
    field: "is_featured",
    headerAlign: "center",
    align: "center",
    flex: 0.8,
    renderCell: (params) => (
      <div className="flex items-center justify-center">
        {params.value ? (
          <CheckmarkOutline className="text-green-500" />
        ) : (
          <CheckmarkOutlineError className="text-red-500" />
        )}
      </div>
    ),
  },
  {
    headerName: "Actions",
    field: "actions", 
    headerAlign: "center",
    align: "center",
    flex: 0.6,
    renderCell: (params) => (
      <Link
        to={`/admin/feedbacks-admin/${params.row.id}`} 
        className="text-blue-500 underline hover:text-blue-700"
      >
        View
      </Link>
    ),
  },
  // {
  //   headerName: "Created On",
  //   field: "created_on",
  //   headerAlign: "center",
  //   align: "center",
  //   flex: 1.2,
  //   // valueGetter: (params:any) => new Date(params.value).toLocaleDateString(),
  // },
  // {
  //   headerName: "Updated On",
  //   field: "updated_on",
  //   headerAlign: "center",
  //   align: "center",
  //   flex: 1.2,
  //   // valueGetter: (params:any) => new Date(params.value).toLocaleDateString(),
  // },
];
