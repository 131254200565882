// src/pages/admin/login/page.tsx api  -- auth/signup, auth/login


import axios from 'axios';
import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate, useNavigation, useRoutes } from 'react-router-dom';
import API from '../../../components/configs/API';
import { CircularProgress } from '@mui/material';
// import { useRouter } from 'next/navigation';
// import API_URL from '@/utils/apiUrl';
// import CircularProgress from '@mui/material/CircularProgress';
// import API from '@/configs/API';
// import { setCookies } from '@/_lib/handel-cookies';
// import { useUser } from '@/context/userContext';


const validationSchema = Yup.object({
  password: Yup.string().required('Password is required'),
  // firstName: Yup.string().required('First name is required'),
  // lastName: Yup.string().required('Last name is required'),
  // fullName: Yup.string(),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Confirm password is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  // phone: Yup.string()
  //   .matches(/^[0-9]+$/, "Phone number must only contain digits")
  //   .min(10, 'Phone number must be at least 10 digits')
  //   .max(15, 'Phone number cannot exceed 15 digits')
  //   .required('Mobile is required'),
});

const AdminLogin = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  // const { userData, clearUserData } = useUser();


  const navigate = useNavigate();
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleLogin = async (values: { email: string; password: string; }) => {
    // console.log('--------------------,login',values.email,values.password)
    setLoading(true);
    try {
    
      const response = await API.post(`${process.env.REACT_APP_API_URL}auth/login/`, {
        email: values.email,
        password: values.password,
      });
      // console.log('oooo',response.data.access_token)
      if (response.status === 200) {
        // clearUserData();

        localStorage.setItem('token', JSON.stringify(response.data.access_token));
        localStorage.setItem('role', JSON.stringify(response.data.role));

        // await Promise.all([
        //   // setCookies("userData", JSON.stringify(response.data)),
        //   // setCookies("token", JSON.stringify(response?.data?.token))
        // ]);

        toast.success('Login successful!');
        navigate('/admin/dashboard');
      }
    } catch (error) {
      const errorMessage = (error as any)?.response?.data.msg;
      if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error('Login failed. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };



  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-[#5ea0eb] via-[#a8c4ca] to-[#91cedc]">
      <div className="flex flex-col lg:w-1/2 max-w-md w-full bg-white rounded-lg shadow-lg p-8 mx-1">
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={validationSchema.pick(['email', 'password'])}
          onSubmit={handleLogin}
        >
          {({ errors, touched }) => (
            <Form>
              <h2 className="text-3xl font-bold text-[#064D9E] mb-6">LOGIN</h2>
              <Field
                name="email"
                type="email"
                placeholder="Enter your Email"
                className="mb-4 px-4 py-2 border rounded-full w-full text-black"
              />
              {errors.email && touched.email ? (
                <div className="text-red-500 text-sm">{errors.email}</div>
              ) : null}

              <div className="relative mb-4">
                <Field
                  name="password"
                  type={passwordVisible ? 'text' : 'password'}
                  placeholder="Enter your password"
                  className="px-4 py-2 border rounded-full w-full text-black"
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm"
                >
                  <span className='text-black'>
                    {passwordVisible ? 'Hide' : 'Show'}
                  </span>
                </button>
              </div>
              {errors.password && touched.password ? (
                <div className="text-red-500 text-sm">{errors.password}</div>
              ) : null}

              <button
                type="submit"
                className="w-full bg-gradient-to-r from-blue-600 to-blue-400 text-white py-2 rounded-full hover:shadow-lg mb-4 flex items-center justify-center"
                disabled={loading}
              >
                {loading ? (<CircularProgress size={20} color={'inherit'} />) : (
                  '↩ Continue')}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AdminLogin;

  
  