import axios from "axios";
import React, { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";

// const brands = [
//   { name: "Educationist", logo: "/BlueLogo.svg" },
//   { name: "Low code system", logo: "/lcs.png" },
//   { name: "Connecting Dots", logo: "/connectingDot.png" },
//   { name: "Infidocs", logo: "/infidocs.png" },
//   { name: "euronet", logo: "/euronet.png" },
//   { name: "octaware_logo-SVG", logo: "/octaware_logo-SVG.svg" }, 
// ];

const TrustedBrands: React.FC = () => {
  const [brands, setBrands] = useState<any[]>([]);

  const fetchBrands = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}trusted_brand`);
      console.log(response.data)
      return response.data.map((client: any) => ({

        image: client.image ? process.env.REACT_APP_BACK_HOST + client.image : null,
      }));

    } catch (error) {
      console.error("Error fetching brands:", error);
      return [];
    }
  };

  useEffect(() => {

    const getBrandData = async () => {
      const brandData = await fetchBrands();
      setBrands(brandData);
      console.log('......................', brandData)
    };

    getBrandData();
  }, []);

  return (
    <section className="md:py-28 py-10 bg-gray-100">
    <Fade duration={2000}>
      <div className=" mx-auto px-4 sm:px-6 lg:px-20 text-center">
        <h2 className="lg:text-3xl text-2xl font-semibold mb-12 text-gray-800">
          Trusted by brands and businesses all over the globe.
        </h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-6 lg:gap-8">
          {brands.map((brand, index) => (
            <div
              key={index}
              className="flex flex-col justify-center  items-center w-full h-40 p-4 bg-white rounded-lg shadow-md hover:shadow-xl hover:scale-105 transition-all duration-300"
            >
              {brand.image ? (
                <img
                  src={brand.image}
                  alt={brand.name}
                  className="h-20 w-auto object-contain"
                />
              ) : (
                <div className="text-gray-500 text-sm italic">
                  No image available
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </Fade>
  </section>
  
  );
};

export default TrustedBrands;
