import React, { useEffect, useState } from 'react';
import CustomDataGrid from '../../../components/CustomDataGrid';
import API from '../../../components/configs/API';
import { useNavigate } from 'react-router-dom';
import { columns } from './columns';

export default function AdminFeedbackPage() {
  const navigate = useNavigate();

  const [rows, setRows] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Fetch feedback data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await API.get(`${process.env.REACT_APP_API_URL}feedbacks`);
        console.log('-------------------------',response.data)
        setRows(response.data);
      } catch (err) {
        setError('Failed to fetch feedback data');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (rows.length === 0) {
    return (
      <div>
        <div className="flex justify-end">
          <button
            onClick={() => navigate("/admin/feedback/new")}
            className="text-blue-400 border rounded py-2 px-4"
          >
            Add New Feedback
          </button>
        </div>
        <div>No Feedback Data to Show...</div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-end">
        <button
          onClick={() => navigate("/admin/feedbacks-admin/new")}
          className="text-blue-400 border rounded py-2 px-4"
        >
          Add New Feedback
        </button>
      </div>
      <CustomDataGrid rows={rows} columns={columns} />
    </div>
  );
}
