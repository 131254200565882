import { title } from "process";
import React from "react";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import {
    WatsonHealthAiStatus,
    Code,
    Mobile,
    PaintBrush,
    ChartLine,
    SettingsAdjust,
    Rocket,
    CloudDownload,
    UserFollow,
    Email,
  } from "@carbon/icons-react";

  

const Services: React.FC = () => {
    const servicesData = [
        {
            id: 1,
            icon: <WatsonHealthAiStatus size={22} className="text-accent"/>, // Replace with actual icon if needed
            title: "Technology Consultancy",
            items: [
                "Tech Advisory",
                "Strategic Planning",
                "Innovation Workshops",
                "IT Roadmap",
                "Cloud Solutions",
            ],
        },
        {
            id: 2,
            icon: <PaintBrush size={22} className="text-accent"/>,
            title: "UX/UI Design",
            items: [
                "UX/UI Excellence",
                "Design Thinking",
                "Interactive Prototypes",
                "Visual Storytelling",
                "Mobile-First Design",
                "Usability Testing",
            ],
        },
        {
            id: 3,
            icon: <Code size={22} className="text-accent"/>,
            title: "Web Development",
            items: [
                "Any CMS to WordPress Migration",
                "Custom WordPress Development",
                "JS MERN Stack Web Apps",
                "Custom Web Apps Development",
                "Responsive Web Development",
                "Performance Optimization",
            ],
        },
        {
            id: 4,
            icon: <Mobile size={22} className="text-accent"/>,
            title: "Mobile Development",
            items: [
                "App Design",
                "iOS Development",
                "Android Solutions",
                "Cross-Platform Apps",
                "UI/UX Excellence",
                "MVP Development",
            ],
        },
        {
            id: 5,
            icon: <ChartLine size={22} className="text-accent"/>, // Replace with actual icon if needed
            title: "Digital Marketing",
            items: [
                "SEO Optimization",
                "PPC Campaigns",
                "Social Media",
                "Content Creation",
                "Email Marketing",
                "Analytics Insight",
            ],
        },
        {
            id: 6,
            icon: <SettingsAdjust size={22} className="text-accent"/>, // Replace with actual icon if needed
            title: "Quality Engineering",
            items: [
                "Test Automation",
                "QA Consulting",
                "Performance Testing",
                "Security Testing",
                "Agile QA",
                "Test Strategy",
            ],
        },
        {
            id: 7,
            icon: <Rocket size={22} className="text-accent"/>, // Replace with actual icon if needed
            title: "DevOps Engineering",
            items: [
                "CI/CD Pipeline",
                "Automation Solutions",
                "Container Orchestration",
                "Infrastructure as Code (IaC)",
                "Continuous Monitoring",
                "Security Integration",
            ],
        },
        {
            id: 8,
            icon: <CloudDownload size={22} className="text-accent"/>, // Replace with actual icon if needed
            title: "Product Engineering",
            items: [
                "Product Ideation",
                "Product Architecture",
                "Product Design",
                "Product Development",
                "Product Testing",
                "Technical Support",
            ],
        },
        {
            id: 9,
            icon: <WatsonHealthAiStatus size={22} className="text-accent"/>, // Replace with actual icon if needed
            title: "AI and ML",
            items: [
                "ML Solutions",
                "AI Consulting",
                "Data Analytics",
                "Predictive Modeling",
                "NLP Services",
                "Deep Learning",
            ],
        },
        {
            id: 10,
            icon: <UserFollow size={22} className="text-accent"/>, // Replace with actual icon if needed
            title: "Support and Maintenance",
            items: [
                "CI/CD DevOps tools setup",
                "Backup & upgrades",
                "Performance & Security monitoring",
                "Server & Uptime monitoring",
                "Ongoing & features development",
                "Access to email / phone support",
            ],
        },
        {
            id: 11,
            icon: <UserFollow size={22} className="text-accent"/>, // Replace with actual icon if needed
            title: "Staff Augmentation",
            items: [
                "On-demand Skilled Talent",
                "Tech Staffing",
                "Agile Teams",
                "Expert Augmentation",
                "Rapid Scaling",
                "Strategic Resourcing",
            ],
        },
        {
            id: 12,
            icon: <Email size={22} className="text-accent"/>, // Replace with a relevant icon if needed
            title: "Get In Touch",
            items: [
                "Let's build and scale your digital products. We are always open to discuss new projects, creative ideas, or opportunities to be part of your vision."
            ],
            isSpecial: true, // Custom property for different styling
            buttonText: "Learn More", // Button text
        }

    ];


    return (
        <section className="md:py-28 py-10 md:px-20 px-4">
            {/* Text Section */}
            <h2 className="text-3xl text- font-semibold md:font-bold pb-1 w-full  bg-gradient-to-r from-accent to-[#3f0d09] bg-clip-text text-transparent">
                Services we help you with to achieve goals
            </h2>
            <p className="text-base mb-6 ">
                Acting as a Catalyst between the brands and the corporates, we take complete responsibility for the timely deliverables and continuous dedicated support.
            </p>

            {/* Cards Section */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 ">
                {servicesData.map((service) => (
                    <div
                        key={service.id}
                        className={`p-4 sm:p-6  shadow-xl md:shadow-sm rounded-lg border bottom-1 border-slate-300 transition-all duration-300 bg-white hover:border-1 hover:border hover:border-gray-400 hover:shadow-2xl hover:scale-105   ${service.isSpecial
                            // ? "bg-gray-700 text-white hover:bg-white hover:text-black hover:shadow-2xl hover:border-slate-200 hover:scale-105 hover:border"
                            // : "bg-gray-700 text-white hover:bg-white hover:text-black hover:shadow-2xl hover:border-slate-200 hover:scale-105 hover:border "
                            }`}
                    >
                        <div className="flex items-center  mb-4">
                            {service.icon}
                            <h3 className="text-xl font-bold ml-2">{service.title}</h3>
                        </div>
                        <ul className="list-disc space-y-2 pl-4">
                            {service.items.map((item, index) => (
                                <li key={index} className="text-sm">
                                    {item}
                                </li>
                            ))}
                        </ul>
                        {service.isSpecial && (
                            <div className="flex justify-center ">
                                <Link to={'/contact'} >
                                    <Button
                                        onClick={() => null}
                                        label="Let's Talk"
                                        circleHover={true}
                                        className="mt-6 sm:mt-10 bg-primary w-52 "
                                    />
                                </Link>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </section>

    );
};

export default Services;
