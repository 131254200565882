import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { BiLogoLinkedinSquare, BiLogoDribbble } from "react-icons/bi";
import { FaTwitter } from "react-icons/fa6";


const BrandsUpdate = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const isNewTeam = id === "new";
// yeh payload change honga , image str rahenga , 
    const initialTeamData = {
        image: {
            src: "",
            alt: "",
        },
        name: "",
        job_title: "",
        description: "",
        linkedin: "",
    };

    const [teamData, setTeamData] = useState(initialTeamData);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!isNewTeam) {
            const fetchTeamData = async () => {
                setIsLoading(true);

                
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_URL}trusted_brand/${id}`
                    );
                    setTeamData(response.data);
                } catch (error) {
                    console.error("Error fetching team data:", error);
                } finally {
                    setIsLoading(false);
                }
            };
            fetchTeamData();
        }
    }, [id, isNewTeam]);

    const validationSchema = Yup.object({
        name: Yup.string()
            .required("Name is required")
            .min(3, "Name must be at least 3 characters"),
      
        image: Yup.mixed().required("Image is required"),
        
 
    });

    const handleSubmit = async (values: typeof initialTeamData) => {
        try {

            const storedUserData = localStorage.getItem('token');
            const parsedToken = storedUserData ? JSON.parse(storedUserData) : null;

            const formData = new FormData();
            formData.append("name", values.name);
            // formData.append("job_title", values.job_title);
            // formData.append("description", values.description);

            if (values.image instanceof File) {
                formData.append("image", values.image);
            }

            formData.append("linkedin", (values.linkedin));

            if (isNewTeam) {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}trusted_brand`,
                    formData,
                    {
                        headers:{Authorization: parsedToken }
                    }
                );
                console.log("Created Team:", response.data);
            } else {
                const response = await axios.put(
                    `${process.env.REACT_APP_API_URL}trusted_brand/${id}`,
                    formData,
                    {
                        headers:{Authorization: parsedToken }
                    }
                );
                console.log("Updated Team:", response.data);
            }

            navigate("/admin/brands");
        } catch (error) {
            console.error("Error submitting team data:", error);
        }
    };

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">
                {isNewTeam ? "Create New Brands" : "Edit Bransds"}
            </h1>
            {isLoading && !isNewTeam ? (
                <p>Loading...</p>
            ) : (
                <Formik
                    initialValues={teamData}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={(values) => handleSubmit(values)}
                >
                    {({ setFieldValue }) => (
                        <Form className="space-y-4">
                            <div>
                                <label className="block mb-2 font-semibold">Name</label>
                                <Field
                                    type="text"
                                    name="name"
                                    className="border border-gray-300 rounded p-2 w-full"
                                />
                                <ErrorMessage
                                    name="name"
                                    component="div"
                                    className="text-red-500 text-sm"
                                />
                            </div>

                            {/* <div>
                                <label className="block mb-2 font-semibold">Job Title</label>
                                <Field
                                    type="text"
                                    name="job_title"
                                    className="border border-gray-300 rounded p-2 w-full"
                                />
                                <ErrorMessage
                                    name="job_title"
                                    component="div"
                                    className="text-red-500 text-sm"
                                />
                            </div> */}

                            {/* <div>
                                <label className="block mb-2 font-semibold">Description</label>
                                <Field
                                    as="textarea"
                                    name="description"
                                    rows={4}
                                    className="border border-gray-300 rounded p-2 w-full"
                                />
                                <ErrorMessage
                                    name="description"
                                    component="div"
                                    className="text-red-500 text-sm"
                                />
                            </div> */}

                            <div>
                                <label className="block mb-2 font-semibold">Image</label>
                                <input
                                    type="file"
                                    onChange={(e) => {
                                        const file = e.target.files?.[0];
                                        if (file) {
                                            setFieldValue("image", file);
                                        }
                                    }}
                                    className="border border-gray-300 rounded p-2 w-full"
                                />
                                <ErrorMessage
                                    name="image"
                                    component="div"
                                    className="text-red-500 text-sm"
                                />
                            </div>

                            {/* <div>
                                <label className="block mb-2 font-semibold">LinkedIN Links</label>
                                <Field
                                    as="textarea"
                                    name="linkedin"
                                    rows={4}
                                    placeholder='Linkedin url'
                                    className="border border-gray-300 rounded p-2 w-full"
                                />
                                <ErrorMessage
                                    name="linkedin"
                                    component="div"
                                    className="text-red-500 text-sm"
                                />
                            </div> */}

                            <button
                                type="submit"
                                className="bg-blue-500 text-white rounded py-2 px-4 hover:bg-blue-600"
                            >
                                {isNewTeam ? "Create Team Member" : "Update Team Member"}
                            </button>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};

export default BrandsUpdate;
