import { GridColDef } from "@mui/x-data-grid";
import { CheckmarkOutline, CheckmarkOutlineError } from "@carbon/icons-react";
import { Link } from "react-router-dom";

export const columns: GridColDef[] = [
    {
        headerName: "Name",
        field: "full_name",
        headerAlign: "left",
        align: "center",
        flex: 1,
    },
    {
        headerName: "Email",
        field: "email",
        headerAlign: "left",
        align: "left",
        flex: 1,
    },
    {
        headerName: "Mobile",
        field: "phone_number",
        headerAlign: "left",
        align: "left",
        flex: 1,
    },
    {
        headerName: "Message",
        field: "message",
        headerAlign: "left",
        align: "left",
        flex: 2,

    },
    {
        headerName: "Service",
        field: "service",
        headerAlign: "center",
        align: "center",
        flex: 1,
    },
    //   {
    //     headerName: "Link",
    //     field: "link",
    //     headerAlign: "left",
    //     align: "left",
    //     flex: 1,
    //   },
    //   {
    //     headerName: "Image",
    //     field: "image",
    //     headerAlign: "center",
    //     align: "center",
    //     width: 120,
    //     renderCell: (params) => {
    //       const imageUrl = params.value ? process.env.REACT_APP_BACK_HOST + params.value : null;
    //       return imageUrl ? (
    //         <img
    //           src={imageUrl}
    //           alt="thumbnail"
    //           className="w-12 h-12 object-cover rounded-md"
    //         />
    //       ) : (
    //         "N/A"
    //       );
    //     },
    //   },
    //   {
    //     headerName: "Actions",
    //     field: "actions", 
    //     headerAlign: "center",
    //     align: "center",
    //     flex: 0.6,
    //     renderCell: (params) => (
    //       <Link
    //         to={`/admin/blogs/${params.row.id}`} 
    //         className="text-blue-500 underline hover:text-blue-700"
    //       >
    //         View
    //       </Link>
    //     ),
    //   },
];
