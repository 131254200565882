import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { BiSolidStar } from "react-icons/bi";
import axios from "axios";
import Button from "../../components/Button";
import { Link } from "react-router-dom";

type ImageProps = {
  src: string;
  alt?: string;
};

type Testimonial = {
  quote: string;
  avatar: ImageProps;
  name: string;
  position: string;
  companyName: string;
  numberOfStars: number;
};

type Feedback = {
  id: string;
  feedback_text: string;
  profile_of_user: string;
  name: string;
  position: string;
};

type ButtonProps = {
  title: string;
  onClick?: () => void;
  variant?: "primary" | "secondary" | "link";
};

type Props = {
  heading: string;
  description: string;
  buttons: ButtonProps[];
  leftTestimonials: Testimonial[];
  rightTestimonials: Testimonial[];
};

export type TestimonialFladdraProps = React.ComponentPropsWithoutRef<"section"> & Partial<Props>;

const TestimonialFladdra = (props: TestimonialFladdraProps) => {
  const [slides, setSlides] = useState<Testimonial[]>([]);

  const { heading, description, buttons } = {
    ...TestimonialDefaults,
    ...props,
  };

  useEffect(() => {
    const fetchFeedback = async () => {
      try {
        const response = await axios.get<Feedback[]>(`${process.env.REACT_APP_BACK_HOST}api/feedbacks`);
        const testimonials = response.data.map((item) => ({
          quote: item.feedback_text,
          avatar: {
            src: `${process.env.REACT_APP_BACK_HOST}${item.profile_of_user}`,
            alt: item.name,
          },
          name: item.name,
          position: item.position,
          companyName: "Company",
          numberOfStars: 5,
        }));
        setSlides(testimonials);
      } catch (error) {
        console.error("Error fetching feedback data:", error);
      }
    };

    fetchFeedback();
  }, []);

  const duplicatedSlides = [...slides, ...slides]; // Duplicate testimonials for infinite scrolling

  return (
    <section className="overflow-hidden px-4 py-12 md:py-28 bg-gray-50 ">
      <div className="container grid max-h-svh auto-cols-fr grid-cols-1 overflow-hidden border border-gray-200 lg:h-[90vh] lg:max-h-[svh] lg:grid-cols-[0.75fr_1fr] lg:overflow-visible">
        {/* Left Section */}
        <div className="flex flex-col justify-center p-8 md:p-12 border-b md:border-b-0">
          <div>
            <h2 className="text-3xl  font-semibold md:font-bold md:text-5xl lg:text-7xl pb-1 w-full  bg-gradient-to-r from-accent to-[#3f0d09] bg-clip-text text-transparent">{heading}</h2>
            <p className="lg:text-2xl text-xl ">{description}</p>
          </div>
          <Link to="/contact">
            <Button
              label="Let&apos;s Talk"
              circleHover={true}
              className="w-1/2 flex-1 mt-8 md:ml-[25%] lg:ml-0 "
            />
          </Link>
        </div>
      
        <div className="overflow-hidden mx-2">
          <InfiniteScroll
            testimonials={duplicatedSlides}
            direction="down"
            duration={200}
          />
        </div>
      </div>
    </section>
  );
};

const InfiniteScroll = ({
  testimonials,
  direction,
  duration = 30, // Default scroll duration
}: {
  testimonials: Testimonial[];
  direction: "up" | "down";
  duration?: number;
}) => {
  const scrollDistance = direction === "down" ? "-100%" : "100%";

  // Dynamically duplicate the slides to ensure seamless looping
  const repeatedTestimonials = Array(2).fill(testimonials).flat(); // Repeat 2 times

  return (
    <motion.div
      className="flex flex-col gap-4"
      animate={{
        y: [0, scrollDistance], // Scroll direction (up or down)
      }}
      transition={{
        repeat: Infinity,
        repeatType: "loop",
        duration, // Adjust this for the speed of the scroll
        ease: "linear",
      }}
    >
      {repeatedTestimonials.map((testimonial, index) => (
        <TestimonialCard key={index} {...testimonial} />
      ))}
    </motion.div>
  );
};

const TestimonialCard = (testimonial: Testimonial) => (
  <div className="flex md:w-2/3 md:ml-[17%]  flex-col  items-start justify-between bg-white border border-gray-200 p-6 md:py-8 rounded-md">
    <div className="mb-5 md:mb-6">
      <div className="mb-6 flex">
        {Array(testimonial.numberOfStars)
          .fill(null)
          .map((_, starIndex) => (
            <BiSolidStar key={starIndex} className="mr-1 text-yellow-500" />
          ))}
      </div>
      <blockquote className="md:text-md">{testimonial.quote}</blockquote>
    </div>
    <div className="flex w-full flex-col items-start text-left md:w-fit md:flex-row md:items-center">
      <img
        src={testimonial.avatar.src}
        alt={testimonial.avatar.alt}
        className="mb-4 h-12 w-12 rounded-full object-cover md:mb-0 md:mr-4"
      />
      <div>
        <p className="font-semibold">{testimonial.name}</p>
        <p>
          <span>{testimonial.position}</span>
        </p>
      </div>
    </div>
  </div>
);

export const TestimonialDefaults: Props = {
  heading: "Our customers tell it better",
  description: "Learn how our customers use our services to scale their businesses.",
  buttons: [
    { title: "Learn More", variant: "primary" },
    { title: "Contact Us", variant: "secondary" },
  ],
  leftTestimonials: [],
  rightTestimonials: [],
};

export default TestimonialFladdra;
