

function BlogHeader() {


    return (
      <div className='lg:h-[60vh] mt-16 bg-slate-200 flex justify-center items-center'>
        <img src="/bg.jpg" className="lg:h-[60vh] h-[20vh] w-full object-cover opacity-30" />

        <div className='text-center  px-10 absolute '>
          <h2 className='text-4xl sm:text-5xl md:text-6xl font-semibold text-gray-800'>
            Our Blog
            {/* <br />
            Designing, developing and growing businesses. */}
          </h2>
          <p className='text-lg sm:text-xl md:text-2xl text-gray-700 mt-4'>
            Designing, developing, and growing businesses.
          </p>
        </div>
      </div>
  
  
    );
  }
  
  export default BlogHeader;
  