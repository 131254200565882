import React from "react";

const HowWeWork: React.FC = () => {
  return (
    <section className="md:py-28 py-10 px-4 md:px-20 ">
      <div className="flex flex-col sm:flex-row justify-between items-center">
        {/* Text Section */}
        <div className="text-center md:text-left  ">
          <h2 className="text-3xl text-accent font-bold mb-4">How Do We Work?</h2>
          <p className="text-base">
            We follow 7D Roadmap to achieve company & client goals.
          </p>
        </div>
      </div>

      {/* Image Section */}
      <div className="flex items-center justify-center">
        {/* Desktop Image */}
        <img 
          src="/howWork.jpg" 
          alt="How We Work" 
          className="hidden sm:block" 
        />

        {/* Mobile Image */}
        <img 
          src="/howWeWork1.jpg" 
          alt="How We Work Mobile" 
          className="sm:hidden" 
        />
      </div>
    </section>
  );
};

export default HowWeWork;
