import { GridColDef } from "@mui/x-data-grid";
import { CheckmarkOutline, CheckmarkOutlineError } from "@carbon/icons-react";
import { Link } from "react-router-dom";

export const columns: GridColDef[] = [
  // {
  //   headerName: "ID",
  //   field: "id", 
  //   headerAlign: "center",
  //   align: "center",
  //   flex: 1,
  // },
  {
    headerName: "Title",
    field: "name",
    headerAlign: "left",
    align: "left",
    flex: 1,
  },
  {
    headerName: "Project",
    field: "project",
    headerAlign: "left",
    align: "left",
    flex: 1,
  },
  {
    headerName: "Description",
    field: "description",
    headerAlign: "left",
    align: "left",
    flex: 2,
  },
  //   {
  //     headerName: "Published",
  //     field: "is_published",
  //     headerAlign: "center",
  //     align: "center",
  //     flex: 0.8,
  //     renderCell: (params) => (
  //       <div className="flex items-center justify-center">
  //         {params.value ? (
  //           <CheckmarkOutline className="text-green-500" />
  //         ) : (
  //           <CheckmarkOutlineError className="text-red-500" />
  //         )}
  //       </div>
  //     ),
  //   },

  {
    headerName: "Image",
    field: "image",
    headerAlign: "center",
    align: "center",
    width: 120,
    renderCell: (params) => {
      const imageUrl = params.value ? process.env.REACT_APP_BACK_HOST + params.value : null;
      return imageUrl ? (
        <img
          src={imageUrl}
          alt="thumbnail"
          className="w-12 h-12 object-cover rounded-md"
        />
      ) : (
        "N/A"
      );
    },
  },
  {
    headerName: "Actions",
    field: "actions",
    headerAlign: "center",
    align: "center",
    flex: 0.6,
    renderCell: (params) => (
      <Link
        to={`/admin/work/${params.row.id}`}
        className="text-blue-500 underline hover:text-blue-700"
      >
        View
      </Link>
    ),
  },
];
