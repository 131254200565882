// src/pages/LandingPage.tsx
import React from 'react';
import BlogHeader from './BlogHeader';
import OurBlog from './OurBlog';
import BlogOverview from './BlogOverview';

const BlogPage: React.FC = () => {
    return (
        <div className=''>
         <BlogHeader/>
         <OurBlog/>
         {/* <BlogOverview/> */}
        </div>
    );
};

export default BlogPage;
