import React from 'react';

const Description: React.FC = () => {
    return (
        <div className=" bg-slate-100 py-10 md:pt-28 ">
            <div className="container mx-auto px-6 sm:px-12 md:px-24 ">
                <p className="lg:text-2xl sm:text-xl md:text-2xl text-gray-700 ">
                    Acting as a Catalyst between the brands and the corporates, We take complete responsibility for the timely deliverables and continuous dedicated support.
                </p>
           
            </div>
        </div>
    );
};

export default Description;
