// src/components/navbar/DrawerBody.js

import React from "react";
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { DashboardReference, Settings, UserFeedback, Blog, Events, EventsAlt, Email } from "@carbon/icons-react";

const DrawerBody = () => {
  const location = useLocation();

  // Define Menu Items
  const menuItems = [
    { title: "Dashboard", path: "/admin", icon: <DashboardReference size={24} /> },
    { title: "Blog", path: "/admin/blog", icon: <Blog size={24} /> },
    { title: "Teams", path: "/admin/teams", icon: <Events size={24} /> },
    { title: "Client", path: "/admin/work", icon: <EventsAlt size={24} /> },
    { title: "Contact us", path: "/admin/contact", icon: <Email size={24} /> },
    { title: "Client Feedback", path: "/admin/feedback-admin", icon: <UserFeedback size={24} /> },
    { title: "Trusted Brands", path: "/admin/brands", icon: <UserFeedback size={24} /> },

    { title: "Settings", path: "#", icon: <Settings size={24} /> },
  ];

  return (
    <Box
      style={{
        width: 250,
        backgroundColor: "#f9fafc",
        height: "100vh", // Full screen height
        position: "fixed", // Fixed position for the drawer
        top: 0,
        left: 0,
        zIndex: 1000, // Ensures drawer stays on top
        boxShadow: "2px 0 5px rgba(0, 0, 0, 0.1)",
      }}
    >
      <List>
        {/* Logo */}
        <div className="flex items-center w-16 my-2 ml-4">
          <img src="/FladdraLogo1.png" className="w-10 h-10 mr-3" alt="Logo" />
          <p className="">Fladdra</p>
        </div>
        <hr className="mb-8" />

        {/* Menu Items */}
        {menuItems.map((item, index) => (
          <Link key={index} to={item.path} style={{ textDecoration: "none", color: "inherit" }}>
            <ListItem disablePadding>
              <ListItemButton selected={location.pathname === item.path}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );
};

export default DrawerBody;
