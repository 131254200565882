// src/pages/LandingPage.tsx
import React from 'react';
import Header from './Header';
import TrustedBrands from './TrustedBrads';
import GetInTouch from './GetInTouch';
import CustomerFeedback from './CustomerFeedback';
import OurWork from './OurWork';
import Services from './Services';
import HowWeWork from './HowWeWork';
import  TestimonialFladdra  from './TestimonialFladdra';
// import { TestimonialFladdra } from './TestimonialFladdra';

// #heading lg:3xl sm:2xl  
// sub heading lg:2xl  sm:lg
// text  lg:base sm:sm  
//px-20 for md lg ,sm:px-4 

const LandingPage: React.FC = () => {
    return (
        <div className=''>
           <Header/>
           <TrustedBrands/>
           {/* <CustomerFeedback title={'Our customers tell it better'} subtitle={'Learn how our customers use our services to scale their businesses.'}/> */}
           {/* <FladdraAtGlance/> */}
           <Services/>
           <OurWork/>
           <GetInTouch/>
           <TestimonialFladdra/>
           <HowWeWork/>

        </div>
    );
};

export default LandingPage;
