import React from "react";
import {
    Education,
    SettingsAdjust,
    Rocket,
    Events
} from '@carbon/icons-react';
import { Fade } from "react-awesome-reveal";

const Process = () => {
    const cardData = [
        {
            id: 1,
            icon: <Education className="text-slate-700 group-hover:text-white" size={100} />,
            title: "Discover",
            summary:
                "Revolutionizing education with AI-powered tools to enhance learning outcomes and streamline teaching processes.",
        },
        {
            id: 2,
            icon: <SettingsAdjust className="text-slate-700 group-hover:text-white" size={100} />,
            title: "Strategize",
            summary:
                "Delivering seamless IT service management solutions to optimize workflows and improve service delivery.",
        },
        {
            id: 3,
            icon: <Events className="text-slate-700 group-hover:text-white" size={100} />,
            title: "Execute",
            summary:
                "Empowering businesses with customer relationship management tools to boost engagement and drive sales growth.",
        },
        {
            id: 4,
            icon: <Rocket className="text-slate-700 group-hover:text-white" size={100} />,
            title: "Launch",
            summary:
                "Empowering businesses with customer relationship management tools to boost engagement and drive sales growth.",
        },
    ];

    return (
        <section className="md:py-28 py-12 bg-white">
            <div className="mx-auto w-[80%] ">
                <h2 className="pb-10 text-2xl md:text-4xl font-bold text-gray-800 bg-gradient-to-r from-accent to-[#3f0d09] bg-clip-text text-transparent ">
                    Our 4-step process to build great products
                </h2>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    {cardData.map((card) => (
                        <div
                            key={card.id}
                            className=" p-4 md:p-6 shadow-xl md:shadow-sm rounded-lg border bottom-1 border-slate-300 transition-all duration-300 bg-white hover:border-1 hover:border hover:border-gray-400 hover:shadow-2xl hover:scale-105"
                        >

 
                            <div className="w-full h-32 flex  justify-center ">
                                {card.icon}
                            </div>

                            <div className="transition-all ease-in-out">
                                <h3 className="text-lg font-bold text-center  text-[#b72b1f] mb-2  ">
                                    {card.title}
                                </h3>
                                <p className="text-gray-600   ">
                                    {card.summary}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Process;
