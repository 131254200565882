import { GridColDef } from "@mui/x-data-grid";
import { CheckmarkOutline, CheckmarkOutlineError } from "@carbon/icons-react";
import { Link, To } from "react-router-dom";
import { BiLogoDribbble, BiLogoLinkedinSquare } from "react-icons/bi";
import { FaTwitter } from "react-icons/fa6";
import { Key } from "react";

export const columns: GridColDef[] = [
  // {
  //   headerName: "ID",
  //   field: "id", 
  //   headerAlign: "center",
  //   align: "center",
  //   flex: 1,
  // },
  {
    headerName: "Name",
    field: "name",
    headerAlign: "left",
    align: "left",
    flex: 1,
  },
  {
    headerName: "Job Ttitle",
    field: "job_title",
    headerAlign: "left",
    align: "left",
    flex: 1,
  },
  {
    headerName: "Description",
    field: "description",
    headerAlign: "left",
    align: "left",
    flex: 2,
    // renderCell: (params) => (
    //   <div>
    //     {params.value.length > 100 ? `${params.value.substring(0, 100)}...` : params.value}
    //   </div>
    // ),
  },
  // {
  //   headerName: "Published",
  //   field: "is_published",
  //   headerAlign: "center",
  //   align: "center",
  //   flex: 0.8,
    // renderCell: (params) => (
    //   <div className="flex items-center justify-center">
    //     {params.value ? (
    //       <CheckmarkOutline className="text-green-500" />
    //     ) : (
    //       <CheckmarkOutlineError className="text-red-500" />
    //     )}
    //   </div>
    // ),
  // },
  {
    headerName: "Linkedin Link",
    field: "linkedin",
    headerAlign: "left",
    align: "left",
    flex: 1,
    // renderCell: (params) => {
    //   const socialLinks = params.value; // Array of social link objects

    //   return (
    //     <div className="flex space-x-2">
    //       {socialLinks.map((link:any, index:any) => (
    //         <Link
    //           key={index}
    //           to={link.href}
    //           className="text-blue-500 hover:underline"
    //           target="_blank" // Optional, opens link in a new tab
    //         >
    //           {link.href}
    //         </Link>
    //       ))}
    //     </div>
    //   );
    // },
  },
  {
    headerName: "Image",
    field: "image",
    headerAlign: "center",
    align: "center",
    width: 120,
    renderCell: (params) => {
      const imageUrl = params.value ? process.env.REACT_APP_BACK_HOST + params.value : null;
      return imageUrl ? (
        <img
          src={imageUrl}
          alt="thumbnail"
          className="w-12 h-12 object-cover rounded-md"
        />
      ) : (
        "N/A"
      );  
    },
  },
  {
    headerName: "Actions",
    field: "actions", 
    headerAlign: "center",
    align: "center",
    flex: 0.6,
    renderCell: (params) => (
      <Link
        to={`/admin/teams/${params.row.id}`} 
        className="text-blue-500 underline hover:text-blue-700"
      >
        View
      </Link>
    ),
  },
];
